import React, { useState, useRef } from 'react';
import { Form, Upload, Radio, Switch, Row, Col, Slider, Select, Space, Divider, InputNumber, Button, Table, Input } from 'antd';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons'; // Додайте цей імпорт
import granuleOptions from './granuleOptions.json';

const CompositionNameAndIcon = ({ normFile, disabled }) => {
  const customItemStyle = { display: 'flex', alignItems: 'center', lineHeight: 'normal' };

  const catFlavors = [
    { label: 'Курочка та креветка', value: 'chicken_shrimp' },
    { label: 'Качка та груша', value: 'duck_pear' },
    { label: 'Індичка та ягоди', value: 'turkey_berries' },
    { label: 'Морський коктейль', value: 'seafood_cocktail' },
  ];

  const dogFlavors = [
    { label: 'Форель та рис', value: 'trout_rice' },
    { label: 'Індичка та лосось', value: 'turkey_salmon' },
    { label: 'Качка та нут', value: 'duck_chickpea' },
    { label: 'Ягнятина та рис', value: 'lamb_rice' },
    { label: 'Качка та індичка', value: 'duck_turkey' },
    { label: 'Телятина з овочами та духмяними травами', value: 'veal_vegetables_herbs' },
  ];

  const purposeOptions = {
    cat: [
      { label: 'для стерилізованих/кастрованих', value: 'sterilised' },
      { label: 'для активних', value: 'active' },
      { label: 'для виведення шерсті зі шлунку', value: 'hairball' },
      { label: 'для вибагливих', value: 'gourmet' },
      { label: 'для підтримки опорно-рухового апарату', value: 'large' },
      { label: 'для британських та шотландських порід', value: 'british' },
      { label: 'здорова шкіра та блиск шерсті', value: 'skin' },
      { label: 'чутливе травлення', value: 'sensitive' },
    ],
    dog: [
      { label: 'для активних', value: 'active' },
      { label: 'здорова шкіра та блиск шерсті', value: 'skin' },
    ],
  };

  const breedSizeOptions = [
    { label: 'MINI', value: 'MINI' },
    { label: 'MEDIUM', value: 'MEDIUM' },
    { label: 'MAXI', value: 'MAXI' },
  ];

  const [customFlavors, setCustomFlavors] = useState({
    cat: [],
    dog: [],
  });
  const [customPurposes, setCustomPurposes] = useState({
    cat: [],
    dog: [],
  });
  const [name, setName] = useState('');
  const [purposeName, setPurposeName] = useState('');
  const [fileList, setFileList] = useState([]);
  const inputRef = useRef(null);
  const purposeInputRef = useRef(null);

  const addFlavor = (petType) => {
    if (name) {
      setCustomFlavors({
        ...customFlavors,
        [petType]: [...customFlavors[petType], { label: name, value: name }],
      });
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const addPurpose = (petType) => {
    if (purposeName) {
      setCustomPurposes({
        ...customPurposes,
        [petType]: [...customPurposes[petType], { label: purposeName, value: purposeName }],
      });
      setPurposeName('');
      setTimeout(() => {
        purposeInputRef.current?.focus();
      }, 0);
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Завантажити</div>
    </div>
  );

  const getTableColumns = (petType, breedSize) => {
    if (petType === 'cat') {
      return [
        { title: 'Фасування', dataIndex: 'packaging', key: 'packaging', align: 'center', width: 150 },
        { title: '8 г', dataIndex: 'pack8g', key: 'pack8g', align: 'center', width: 150 },
        { title: '200 г', dataIndex: 'pack200g', key: 'pack200g', align: 'center', width: 150 },
        { title: '400 г', dataIndex: 'pack400g', key: 'pack400g', align: 'center', width: 150 },
        { title: '1.6 кг', dataIndex: 'pack1_6kg', key: 'pack1_6kg', align: 'center', width: 150 },
        { title: '10 кг', dataIndex: 'pack10kg', key: 'pack10kg', align: 'center', width: 150 },
        { title: 'Коробка картонна (0.4х30)', dataIndex: 'cartonBox', key: 'cartonBox', align: 'center', width: 150 },
      ];
    } else if (petType === 'dog' && breedSize.includes('MINI') && breedSize.includes('MEDIUM')) {
      return [
        { title: 'Фасування', dataIndex: 'packaging', key: 'packaging', align: 'center', width: 150 },
        { title: '8 г', dataIndex: 'pack8g', key: 'pack8g', align: 'center', width: 150 },
        { title: '300 г', dataIndex: 'pack300g', key: 'pack300g', align: 'center', width: 150 },
        { title: '700 г', dataIndex: 'pack700g', key: 'pack700g', align: 'center', width: 150 },
        { title: '1.6 кг', dataIndex: 'pack1_6kg', key: 'pack1_6kg', align: 'center', width: 150 },
        { title: '10 кг', dataIndex: 'pack10kg', key: 'pack10kg', align: 'center', width: 150 },
        { title: 'Коробка картонна (0.7х15)', dataIndex: 'cartonBox', key: 'cartonBox', align: 'center', width: 150 },
      ];
    } else if (petType === 'dog' && breedSize.includes('MEDIUM')) {
      return [
        { title: 'Фасування', dataIndex: 'packaging', key: 'packaging', align: 'center', width: 150 },
        { title: '300 г', dataIndex: 'pack300g', key: 'pack300g', align: 'center', width: 150 },
        { title: '1.6 кг', dataIndex: 'pack1_6kg', key: 'pack1_6kg', align: 'center', width: 150 },
        { title: '10 кг', dataIndex: 'pack10kg', key: 'pack10kg', align: 'center', width: 150 },
      ];
    } else if (petType === 'dog' && breedSize.includes('MAXI')) {
      return [
        { title: 'Фасування', dataIndex: 'packaging', key: 'packaging', align: 'center', width: 150 },
        { title: '3 кг', dataIndex: 'pack3kg', key: 'pack3kg', align: 'center', width: 150 },
        { title: '10 кг', dataIndex: 'pack10kg', key: 'pack10kg', align: 'center', width: 150 },
      ];
    } else {
      return [
        { title: 'Фасування', dataIndex: 'packaging', key: 'packaging', align: 'center', width: 150 },
        { title: '8 г', dataIndex: 'pack8g', key: 'pack8g', align: 'center', width: 150 },
        { title: '300 г', dataIndex: 'pack300g', key: 'pack300g', align: 'center', width: 150 },
        { title: '700 г', dataIndex: 'pack700g', key: 'pack700g', align: 'center', width: 150 },
        { title: '1.6 кг', dataIndex: 'pack1_6kg', key: 'pack1_6kg', align: 'center', width: 150 },
        { title: '10 кг', dataIndex: 'pack10kg', key: 'pack10kg', align: 'center', width: 150 },
        { title: 'Коробка картонна (0.7х15)', dataIndex: 'cartonBox', key: 'cartonBox', align: 'center', width: 150 },
      ];
    }
  };

  const getTableData = (petType, breedSize) => {
    const generateBarcodeInput = (size) => (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Form.Item name={`barcode_${size}`} noStyle>
        <Form.Item name={`barcode_${size}`} noStyle>
          <InputNumber 
            style={{ width: '100%', textAlign: 'center' }} 
            maxLength={13} 
          />
        </Form.Item>
        </Form.Item>
          <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => downloadBarcodeSVG(size)}
              style={{ padding: '0 8px' }}
          />
          <div style={{ display: 'none' }}>
              <svg id={`barcode_image_${size}`} />
          </div>
      </div>
  );
    
    const downloadBarcodeSVG = (size) => {
      const svg = document.getElementById(`barcode_image_${size}`);
      if (svg) {
        const serializer = new XMLSerializer();
        const source = serializer.serializeToString(svg);
    
        const svgBlob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
        const svgUrl = URL.createObjectURL(svgBlob);
    
        const downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = `barcode_${size}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        console.error(`SVG element for barcode ${size} not found`);
      }
    };

    if (petType === 'cat') {
      return [
        {
          key: '1',
          packaging: 'Допустимі відхилення',
          pack8g: '9%',
          pack200g: '3%',
          pack400g: '3%',
          pack1_6kg: '1.5%',
          pack10kg: '1.5%',
          cartonBox: '-',
        },
        {
          key: '2',
          packaging: 'Штрих-код',
          pack8g: generateBarcodeInput('8g'),
          pack200g: generateBarcodeInput('200g'),
          pack400g: generateBarcodeInput('400g'),
          pack1_6kg: generateBarcodeInput('1_6kg'),
          pack10kg: generateBarcodeInput('10kg'),
          cartonBox: generateBarcodeInput('cartonBox'),
        },
      ];
    } else if (petType === 'dog' && breedSize.includes('MINI') && breedSize.includes('MEDIUM')) {
      return [
        {
          key: '1',
          packaging: 'Допустимі відхилення',
          pack8g: '9%',
          pack300g: '3%',
          pack700g: '3%',
          pack1_6kg: '1.5%',
          pack10kg: '1.5%',
          cartonBox: '-',
        },
        {
          key: '2',
          packaging: 'Штрих-код',
          pack8g: generateBarcodeInput('8g'),
          pack300g: generateBarcodeInput('300g'),
          pack700g: generateBarcodeInput('700g'),
          pack1_6kg: generateBarcodeInput('1_6kg'),
          pack10kg: generateBarcodeInput('10kg'),
          cartonBox: generateBarcodeInput('cartonBox'),
        },
      ];
    } else if (petType === 'dog' && breedSize.includes('MEDIUM')) {
      return [
        {
          key: '1',
          packaging: 'Допустимі відхилення',
          pack300g: '3%',
          pack1_6kg: '1.5%',
          pack10kg: '1.5%',
        },
        {
          key: '2',
          packaging: 'Штрих-код',
          pack300g: generateBarcodeInput('300g'),
          pack1_6kg: generateBarcodeInput('1_6kg'),
          pack10kg: generateBarcodeInput('10kg'),
        },
      ];
    } else if (petType === 'dog' && breedSize.includes('MAXI')) {
      return [
        {
          key: '1',
          packaging: 'Допустимі відхилення',
          pack3kg: '1.5%',
          pack10kg: '1.5%',
        },
        {
          key: '2',
          packaging: 'Штрих-код',
          pack3kg: generateBarcodeInput('3kg'),
          pack10kg: generateBarcodeInput('10kg'),
        },
      ];
    } else {
      return [
        {
          key: '1',
          packaging: 'Допустимі відхилення',
          pack8g: '9%',
          pack300g: '3%',
          pack700g: '3%',
          pack1_6kg: '1.5%',
          pack10kg: '1.5%',
          cartonBox: '-',
        },
        {
          key: '2',
          packaging: 'Штрих-код',
          pack8g: generateBarcodeInput('8g'),
          pack300g: generateBarcodeInput('300g'),
          pack700g: generateBarcodeInput('700g'),
          pack1_6kg: generateBarcodeInput('1_6kg'),
          pack10kg: generateBarcodeInput('10kg'),
          cartonBox: generateBarcodeInput('cartonBox'),
        },
      ];
    }
  };

  const getContainerWidth = (petType, breedSize) => {
    let numColumns = getTableColumns(petType, breedSize).length;
    return numColumns * 180;
  };

  return (
    <>
      <style>
        {`
          .ant-table-cell {
            padding: 6px 6px !important;
          }
          .ant-input-number-input {
            padding: 4px 4px !important;
          }
        `}
      </style>
      <Form.Item style={{ marginBottom: 24 }}>
        <Row align="middle">
          <Col flex="none" style={{ marginRight: 16 }}>
            Тип тварини:
          </Col>
          <Col flex="auto">
            <Form.Item
              name="petType"
              noStyle
              rules={[{ required: true, message: 'Будь ласка, виберіть тип тварини!' }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value="cat">кіт</Radio>
                <Radio value="dog">собака</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ marginBottom: 24 }}>
        <Row align="middle">
          <Col flex="none" style={{ marginRight: 16 }}>
            Вікова категорія:
          </Col>
          <Col flex="auto">
            <Form.Item
              name="ageType"
              noStyle
              rules={[{ required: true, message: 'Будь ласка, виберіть вікову категорію!' }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value="young">малюк</Radio>
                <Radio value="adult">дорослий</Radio>
                <Radio value="old">літній</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ marginBottom: 24 }}>
        <Row>
          <Col flex="none" style={{ marginRight: 16 }}>
            Вміст зерна:
          </Col>
          <Col flex="auto">
            <Form.Item
              name="grainContent"
              noStyle
              rules={[{ required: true, message: 'Будь ласка, виберіть вміст зерна!' }]}
            >
              <Slider
                marks={{
                  0: '0',
                  50: 'низький',
                  100: 'стандарт',
                }}
                step={50}
                defaultValue={100}
                tooltip={{ open: false }}
                disabled={disabled}
                style={{ width: '300px', marginLeft: '16px' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ marginBottom: 24 }}>
        <Row align="middle">
          <Col flex="none" style={{ marginRight: 16 }}>
            Гіпоалергенний:
          </Col>
          <Col flex="auto">
            <Form.Item
              name="hypoallergenic"
              valuePropName="checked"
              noStyle
            >
              <Switch disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ marginBottom: 24 }} shouldUpdate={(prevValues, currentValues) => prevValues.petType !== currentValues.petType || prevValues.breedSizeDog !== currentValues.breedSizeDog}>
        {({ getFieldValue }) => {
          const petType = getFieldValue('petType');
          const breedSize = getFieldValue('breedSizeDog') || [];

          if (petType) {
            const containerWidth = getContainerWidth(petType, breedSize);
            return (
              <>
                {petType === 'dog' && (
                  <Form.Item style={{ marginBottom: 24 }}>
                    <Row align="middle">
                      <Col flex="none" style={{ marginRight: 10 }}>
                        Розмір породи:
                      </Col>
                      <Col flex="auto">
                        <Form.Item name="breedSizeDog" noStyle>
                          <Select
                            mode="multiple"
                            style={{ width: '300px' }}
                            placeholder="Виберіть розмір породи"
                            options={breedSizeOptions}
                            disabled={disabled}
                            optionLabelProp="label"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                )}
                <Form.Item style={{ marginBottom: 24 }}>
                  <Row align="middle">
                    <Col flex="none" style={{ marginRight: 19 }}>
                      Призначення:
                    </Col>
                    <Col flex="auto">
                      <Form.Item name={`purpose${petType.charAt(0).toUpperCase() + petType.slice(1)}`} noStyle>
                        <Select
                          mode="multiple"
                          style={{ width: '300px' }}
                          placeholder="Виберіть призначення"
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                  placeholder="Введіть нове призначення"
                                  ref={purposeInputRef}
                                  value={purposeName}
                                  onChange={(e) => setPurposeName(e.target.value)}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={() => addPurpose(petType)}
                                >
                                  Додати призначення
                                </Button>
                              </Space>
                            </>
                          )}
                          options={[...purposeOptions[petType], ...customPurposes[petType]].map(opt => ({
                            label: opt.label,
                            value: opt.value,
                          }))}
                          disabled={disabled}
                          optionLabelProp="label"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item style={{ marginBottom: 24 }}>
                  <Row align="middle">
                    <Col flex="none" style={{ marginRight: 30 }}>
                      Смак:
                    </Col>
                    <Col flex="auto">
                      <Form.Item name={`flavor${petType.charAt(0).toUpperCase() + petType.slice(1)}`} noStyle>
                        <Select
                          style={{ width: '342px' }}
                          placeholder={`Виберіть смак для ${petType === 'cat' ? 'котів' : 'собак'}`}
                          dropdownRender={menu => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                  placeholder="Введіть новий смак"
                                  ref={inputRef}
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={() => addFlavor(petType)}
                                >
                                  Додати смак
                                </Button>
                              </Space>
                            </>
                          )}
                          options={[...petType === 'cat' ? catFlavors : dogFlavors, ...customFlavors[petType]].map(opt => ({
                            label: opt.label,
                            value: opt.value,
                          }))}
                          disabled={disabled}
                          optionLabelProp="label"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item style={{ marginBottom: 24 }}>
                  <Row align="middle">
                    <Col flex="none" style={{ marginRight: 11 }}>
                      Гранула:
                    </Col>
                    <Col flex="auto">
                      <Form.Item name={`granule${petType.charAt(0).toUpperCase() + petType.slice(1)}`} noStyle>
                        <Select
                          style={{ width: '342px' }}
                          placeholder={`Виберіть гранули для ${petType === 'cat' ? 'котів' : 'собак'}`}
                          dropdownRender={menu => (
                            <div>{menu}</div>
                          )}
                          options={granuleOptions[petType].map(opt => ({
                            label: (
                              <Space style={customItemStyle}>
                                <img
                                  src={opt.icon}
                                  alt={opt.label}
                                  style={{ width: '25px', height: '25px', objectFit: 'contain' }}
                                />
                                {opt.label}
                              </Space>
                            ),
                            value: opt.value,
                          }))}
                          disabled={disabled}
                          optionLabelProp="label"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item style={{ marginBottom: 24 }}>
                  <div style={{ overflowX: 'auto', maxWidth: `${containerWidth}px` }}>
                    <Table
                      columns={getTableColumns(petType, breedSize)}
                      dataSource={getTableData(petType, breedSize)}
                      pagination={false}
                      bordered
                      style={{ marginTop: 16, minWidth: 'fit-content' }}
                      rowClassName={() => 'custom-table-row'}
                    />
                  </div>
                </Form.Item>
              </>
            );
          }

          return null;
        }}
      </Form.Item>

      <Form.Item
        label="Іконка"
        name="icon"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        style={{ marginBottom: 24 }}
      >
        <Upload
          action="/api/upload"
          listType="picture-card"
          fileList={fileList}
          onChange={handleChange}
          showUploadList={{ showPreviewIcon: false }}
          disabled={disabled}
          maxCount={1}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>
    </>
  );
};

export default CompositionNameAndIcon;
