//home/servers/feedcraft/client/src/components/GroupedProducts.js

import React from 'react';
import ProductCard from './ProductCard';

const GroupedProducts = ({ products }) => {
  // Grouping products by parent_article or id if parent_article is absent
  const groupedProducts = products.reduce((acc, product) => {
    const parentArticle = product.parent_article || product.id;

    if (!acc[parentArticle]) {
      acc[parentArticle] = [];
    }

    acc[parentArticle].push(product);
    return acc;
  }, {});

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      {Object.keys(groupedProducts).map(parentArticle => {
        const group = groupedProducts[parentArticle];
        const mainProduct = group.find(product => product.id === product.parent_article) || group[0];
        const availableWeights = group
          .map(prod => ({
            id: prod.id,
            value: prod.vaga || 'Невідомо',
          }))
          .filter(weight => weight.value !== 'Невідомо'); // Filter out products without weight

        return (
          <div key={mainProduct.id} style={{ width: '30%', marginBottom: '20px', padding: '0 10px' }}>
            <ProductCard product={mainProduct} availableWeights={availableWeights} />
          </div>
        );
      })}
    </div>
  );
};

export default GroupedProducts;
