import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Layout, Menu, Button, message, Form, Cascader, List, InputNumber, Space, Modal, Card, Dropdown, Input, Spin, Select, Row, Col } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, EditOutlined, PlusCircleOutlined, SafetyCertificateOutlined, ExperimentOutlined, PlusOutlined, DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import JsBarcode from 'jsbarcode';
import CompositionNameAndIcon from '../components/CompositionNameAndIcon';
import availableIngredientsData from '../config/availableIngredients.json';
import NutritionalSupplements from '../components/NutritionalSupplements';

const { Sider, Content, Header } = Layout;
const { useForm } = Form;

const items = [
  { key: '1', icon: <EditOutlined />, label: 'Назва складу' },
  { key: '2', icon: <PlusCircleOutlined />, label: 'Інгредієнти складу' },
  { key: '3', icon: <SafetyCertificateOutlined />, label: 'Харчові добавки' },
  { key: '4', icon: <ExperimentOutlined />, label: 'Гарантований аналіз' },
  { key: '5', icon: <ExperimentOutlined />, label: 'Калорійність' },
];

const transformDataToCascaderOptions = (data, addedIngredients) => {
  const categories = {};

  data.ingredients.forEach((ingredient) => {
    if (!categories[ingredient.category]) {
      categories[ingredient.category] = [];
    }

    const subIngredients = ingredient.subIngredients.map((sub) => ({
      value: sub.name,
      label: sub.name,
      disabled: addedIngredients.includes(sub.name),
    }));

    categories[ingredient.category].push({
      value: ingredient.name,
      label: ingredient.name,
      children: subIngredients.length > 0 ? subIngredients : null,
      disabled: addedIngredients.includes(ingredient.name),
    });
  });

  return Object.keys(categories).map((category) => ({
    value: category,
    label: category,
    children: categories[category],
  }));
};

const flavorLabels = {
  chicken_shrimp: 'Курочка та креветка',
  duck_pear: 'Качка та груша',
  turkey_berries: 'Індичка та ягоди',
  seafood_cocktail: 'Морський коктейль',
  trout_rice: 'Форель та рис',
  turkey_salmon: 'Індичка та лосось',
  duck_chickpea: 'Качка та нут',
  lamb_rice: 'Ягнятина та рис',
  duck_turkey: 'Качка та індичка',
  veal_vegetables_herbs: 'Телятина з овочами та духмяними травами',
};

const purposeLabels = {
  sterilised: 'для стерилізованих/кастрованих',
  active: 'для активних',
  hairball: 'для виведення шерсті зі шлунку',
  gourmet: 'для вибагливих',
  large: 'для підтримки опорно-рухового апарату',
  british: 'для британських та шотландських порід',
  skin: 'здорова шкіра та блиск шерсті',
  sensitive: 'з чутливим травленням',
};

const breedSizeLabels = {
  MINI: 'малих',
  MEDIUM: 'середніх',
  MAXI: 'великих',
};

const breedSizeOrder = ['MINI', 'MEDIUM', 'MAXI'];

const AddCompositionPage = () => {
  const { role } = useContext(AuthContext);
  const [selectedKey, setSelectedKey] = useState('1');
  const [collapsed, setCollapsed] = useState(false);
  const [form] = useForm();
  const [ingredientForm] = useForm();
  const [subForm] = useForm();
  const [editForm] = useForm();
  const navigate = useNavigate();
  const { articleNumber } = useParams();
  const [generatedName, setGeneratedName] = useState('');
  const [loading, setLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [subIngredients, setSubIngredients] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentIngredient, setCurrentIngredient] = useState(null);
  const [supplementValues, setSupplementValues] = useState({});
  const [cascaderOptions, setCascaderOptions] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const viewOnly = urlParams.get('view') === 'true';

  useEffect(() => {
    if (!role) {
      console.log("Role is undefined, returning null");
      return;
    }

    console.log("Rendering content for role:", role);

    const fetchIngredients = () => {
      try {
        console.log("Fetching available ingredients...");
        setAvailableIngredients(availableIngredientsData.ingredients);
        setCascaderOptions(transformDataToCascaderOptions(availableIngredientsData, ingredients.map(ing => ing.name)));
        console.log("Available ingredients fetched.");
      } catch (error) {
        message.error(`Failed to fetch available ingredients: ${error.message}`);
      }
    };

    const fetchData = async () => {
      if (articleNumber) {
        setLoading(true);
        try {
          console.log(`Fetching composition data for article number: ${articleNumber}`);
          const response = await axios.get(`/api/compositions/${articleNumber}`);
          const data = response.data;
          console.log('Fetched composition data:', data);
          if (data.icon) {
            data.icon = [{
              uid: '-1',
              name: data.icon.split('/').pop(),
              status: 'done',
              url: data.icon,
            }];
          }
          const formData = {
            ...data,
            flavorCat: data.petType === 'cat' ? data.flavor : undefined,
            flavorDog: data.petType === 'dog' ? data.flavor : undefined,
            purposeCat: data.petType === 'cat' ? data.purpose : undefined,
            purposeDog: data.petType === 'dog' ? data.purpose : undefined,
            granuleCat: data.petType === 'cat' ? data.granule : undefined,
            granuleDog: data.petType === 'dog' ? data.granule : undefined,
            breedSizeDog: data.petType === 'dog' ? data.breedSize : undefined,
          };
          form.setFieldsValue(formData);
          setIngredients(data.ingredients || []);
          const initialSupplementValues = {};
          data.nutritionalSupplements.forEach(supplement => {
            initialSupplementValues[supplement.name] = supplement.value;
          });
          setSupplementValues(initialSupplementValues);
          console.log('Initial supplement values set:', initialSupplementValues);
          updateGeneratedName(formData); // Ensure the name is generated after loading
        } catch (error) {
          message.error(`Failed to load composition data: ${error.message}`);
          console.error('Error loading composition data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        form.setFieldsValue({ 
          hypoallergenic: false,
          grainContent: 100,
          icon: [{
            uid: '-1',
            name: 'default-icon.png',
            status: 'done',
            url: '/uploads/1722883991430-icons8-new-100 (1).png',
          }],
          articleNumber: uuidv4().slice(0, 8), // генеруємо унікальний артикул довжиною 8 символів
        });
        setLoading(false);
      }
    };

    fetchIngredients();
    fetchData();
  }, [articleNumber, form, role]);

  const updateGeneratedName = (values) => {
    // Логіка для генерації імені
    const { petType, ageType, hypoallergenic, flavorCat, flavorDog, grainContent, purposeCat, purposeDog, breedSizeDog } = values;

    let ageTypeText = '';

    switch (ageType) {
      case 'adult':
        ageTypeText = petType === 'cat' ? 'дорослих котів' : 'дорослих собак';
        break;
      case 'young':
        ageTypeText = petType === 'cat' ? 'кошенят' : 'цуценят';
        break;
      case 'old':
        ageTypeText = petType === 'cat' ? 'котів похилого віку' : 'собак похилого віку';
        break;
      default:
        break;
    }

    const grainFreeText = grainContent === 50 ? 'низькозерновий' : grainContent === 0 ? 'беззерновий' : '';
    const hypoallergenicText = hypoallergenic ? 'гіпоалергенний' : '';
    const flavorValue = petType === 'cat' ? flavorCat : flavorDog;
    const flavorText = flavorLabels[flavorValue] || '';
    const purposeValue = petType === 'cat' ? purposeCat : purposeDog;

    let finalPurposeText = '';
    if (purposeValue.includes('sterilised')) {
      if (purposeValue.includes('active')) {
        finalPurposeText = 'для активних стерилізованих/кастрованих';
      } else if (purposeValue.includes('hairball')) {
        finalPurposeText = 'для виведення шерсті зі шлунку стерилізованих/кастрованих';
      } else if (purposeValue.includes('gourmet')) {
        finalPurposeText = 'для вибагливих стерилізованих/кастрованих';
      } else if (purposeValue.includes('large')) {
        finalPurposeText = 'для підтримки опорно-рухового апарату стерилізованих/кастрованих';
      } else if (purposeValue.includes('skin')) {
        finalPurposeText = 'здорова шкіра та блиск шерсті для стерилізованих/кастрованих';
      } else if (purposeValue.includes('sensitive')) {
        finalPurposeText = `для стерилізованих/кастрованих ${ageTypeText} з чутливим травленням`;
      } else {
        finalPurposeText = 'для стерилізованих/кастрованих';
      }
    } else if (purposeValue.includes('sensitive')) {
      finalPurposeText = `для ${ageTypeText} з чутливим травленням`;
    } else if (purposeValue.includes('skin')) {
      finalPurposeText = `${purposeLabels.skin} для`;
    } else if (purposeValue.length > 0) {
      finalPurposeText = purposeValue.map(purpose => purposeLabels[purpose]).join(', ').replace(/(^|,\s)для/g, '');
      finalPurposeText = `для ${finalPurposeText}`;
    }

    let breedSizeText = '';
    if (petType === 'dog' && breedSizeDog) {
      const breedSizeLabelsList = breedSizeDog.sort((a, b) => breedSizeOrder.indexOf(a) - breedSizeOrder.indexOf(b))
                                               .map(size => breedSizeLabels[size]);

      if (breedSizeLabelsList.length === 1) {
        breedSizeText = `${breedSizeLabelsList[0]} порід`;
      } else if (breedSizeLabelsList.length === 2) {
        breedSizeText = `${breedSizeLabelsList[0]} та ${breedSizeLabelsList[1]} порід`;
      } else {
        breedSizeText = 'усіх порід';
      }
    }

    const ageTypePurposeText = finalPurposeText.includes(ageTypeText) ? finalPurposeText : `${finalPurposeText} ${ageTypeText}`;

    if (petType === 'dog' && purposeValue.includes('active') && ageType === 'adult') {
      setGeneratedName(`Повнораціонний ${grainFreeText} ${hypoallergenicText} сухий корм ${ageTypePurposeText} і юніорів ${breedSizeText} ${flavorText}`);
    } else {
    setGeneratedName(`Повнораціонний ${grainFreeText} ${hypoallergenicText} сухий корм ${ageTypePurposeText} ${breedSizeText} ${flavorText}`);
  };
}
const handleMenuClick = (e) => {
  setSelectedKey(e.key);
};

  const generateBarcodesOnLoad = () => {
    if (articleNumber) {
        const formValues = form.getFieldsValue();
        const barcodeFields = ['8g', '300g', '700g', '1_6kg', '10kg', 'cartonBox'];

        barcodeFields.forEach(size => {
            const element = document.getElementById(`barcode_image_${size}`);
            console.log(`element: ${element}`);
            const barcode = formValues[`barcode_${size}`];

            if (barcode && element) {
                console.log(`Generating barcode ${barcode} in #barcode_image_${size}`);

                JsBarcode(element, `${barcode}`, {
                    format: "EAN13",
                    lineColor: "#002d5a",
                    width: 2,
                    height: 100,
                    displayValue: true,
                    fontSize: 18,
                    margin: 0,
                });
            } else if (!element) {
                console.error(`Element #barcode_image_${size} not found`);
            }
        });
    }
};


  useEffect(() => {
    const fetchIngredients = () => {
      // Логіка для завантаження інгредієнтів
      setAvailableIngredients(availableIngredientsData.ingredients);
    };

    const fetchData = async () => {
      if (articleNumber) {
        setLoading(true);
        try {
          const response = await axios.get(`/api/compositions/${articleNumber}`);
          const data = response.data;

          if (data.icon) {
            data.icon = [{
              uid: '-1',
              name: data.icon.split('/').pop(),
              status: 'done',
              url: data.icon,
            }];
          }

          const barcodes = {};
          if (data.barcodes && data.barcodes.length) {
            data.barcodes.forEach(({ size, barcode }) => {
              barcodes[`barcode_${size}`] = barcode;
            });
          }

          const formData = {
            ...data,
            flavorCat: data.petType === 'cat' ? data.flavor : undefined,
            flavorDog: data.petType === 'dog' ? data.flavor : undefined,
            purposeCat: data.petType === 'cat' ? data.purpose : undefined,
            purposeDog: data.petType === 'dog' ? data.purpose : undefined,
            granuleCat: data.petType === 'cat' ? data.granule : undefined,
            granuleDog: data.petType === 'dog' ? data.granule : undefined,
            breedSizeDog: data.petType === 'dog' ? data.breedSize : undefined,
            ...barcodes,
          };

          form.setFieldsValue(formData);
          setIngredients(data.ingredients || []);
          const initialSupplementValues = {};
          data.nutritionalSupplements.forEach(supplement => {
            initialSupplementValues[supplement.name] = supplement.value;
          });
          setSupplementValues(initialSupplementValues);
          updateGeneratedName(formData);
        } catch (error) {
          message.error(`Failed to load composition data: ${error.message}`);
        } finally {
          setLoading(false);
          generateBarcodesOnLoad(); // Генерація штрих-кодів після завантаження
        }
      } else {
        form.setFieldsValue({ 
          hypoallergenic: false,
          grainContent: 100,
          icon: [{
            uid: '-1',
            name: 'default-icon.png',
            status: 'done',
            url: '/uploads/1722883991430-icons8-new-100 (1).png',
          }],
          articleNumber: uuidv4().slice(0, 8),
        });
        setLoading(false);
      }
    };

    fetchIngredients();
    fetchData();
  }, [articleNumber, form, role]);

  const handleSave = async () => {
    try {
        const values = await form.validateFields();
        updateGeneratedName(values); // Ensure the generatedName is updated

        const { petType, ageType, hypoallergenic, icon, articleNumber: formArticleNumber, grainContent, flavorCat, flavorDog, purposeCat, purposeDog, granuleCat, granuleDog, breedSizeDog } = values;

        const iconPath = icon && icon[0] && (icon[0].url || icon[0].response.filePath) ? (icon[0].url || icon[0].response.filePath) : '';

        if (!iconPath) {
            message.error('Будь ласка, завантажте іконку.');
            return;
        }

        const packagingSizes = ['8g', '300g', '700g', '1_6kg', '10kg', 'cartonBox'];
        const barcodes = packagingSizes.map(size => {
            const barcodeValue = values[`barcode_${size}`];
            return barcodeValue ? { size, barcode: String(barcodeValue) } : null;
        }).filter(item => item !== null);

        const data = {
            petType,
            ageType,
            hypoallergenic,
            icon: iconPath,
            name: generatedName,
            articleNumber: formArticleNumber || articleNumber,
            grainContent,
            flavor: petType === 'cat' ? flavorCat : flavorDog,
            purpose: petType === 'cat' ? purposeCat : purposeDog,
            granule: petType === 'cat' ? granuleCat : granuleDog,
            breedSize: petType === 'dog' ? breedSizeDog : undefined,
            ingredients: ingredients.length > 0 ? ingredients.map(ingredient => ({
                name: ingredient.name,
                canHaveSubIngredients: ingredient.canHaveSubIngredients,
                percentage: ingredient.percentage || "",
                subIngredients: ingredient.subIngredients.map(sub => ({
                    name: sub.name,
                    percentage: sub.percentage || ""
                }))
            })) : [],
            nutritionalSupplements: Object.keys(supplementValues)
                .filter(key => supplementValues[key])
                .map(key => ({
                    name: key,
                    value: supplementValues[key]
                })),
            barcodes,
        };

        console.log('Data to be saved:', JSON.stringify(data, null, 2));

        if (articleNumber) {
            const response = await axios.put(`/api/compositions/${articleNumber}`, data);
            console.log('Saved composition data:', response.data);
            message.success('Композицію успішно оновлено');
        } else {
            const response = await axios.post('/api/compositions', data);
            console.log('Saved composition data:', response.data);
            navigate(`/add-composition/${response.data.articleNumber}`);
            message.success('Композицію успішно збережено');
        }

        // Генерація штрих-кодів після збереження
        generateBarcodesOnLoad();
    } catch (error) {
        message.error(`Не вдалося зберегти композицію: ${error.message}`);
        console.error('Error saving composition:', error);
    }
};

  const handleExit = () => {
    navigate('/ingredient-generator');
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const addIngredient = () => {
    ingredientForm.validateFields().then(values => {
      const selectedIngredient = availableIngredients.find(ingredient => ingredient.name === values.name[values.name.length - 1]);
      if (ingredients.some(ingredient => ingredient.name === selectedIngredient.name)) {
        message.error('This ingredient has already been added.');
        return;
      }
      const subIngredientsToAdd = values.name.length > 1 ? values.name.slice(1).map(subName => ({
        name: subName,
        percentage: "",
        canHaveSubIngredients: false,
      })) : [];
      setIngredients([...ingredients, { ...selectedIngredient, name: selectedIngredient.name, id: Date.now(), percentage: values.percentage, subIngredients: subIngredientsToAdd }]);
      ingredientForm.resetFields();
      setCascaderOptions(transformDataToCascaderOptions(availableIngredientsData, [...ingredients.map(ing => ing.name), selectedIngredient.name]));
    });
  };

  const addSubIngredient = () => {
    subForm.validateFields().then(values => {
      const updatedSubIngredients = { ...subIngredients };
      if (!updatedSubIngredients[currentIngredient.id]) {
        updatedSubIngredients[currentIngredient.id] = [];
      }
      const totalSubPercentage = updatedSubIngredients[currentIngredient.id].reduce((acc, sub) => acc + sub.percentage, 0);
      if (totalSubPercentage + values.percentage > currentIngredient.percentage) {
        message.error(`The total percentage of sub-ingredients cannot exceed ${currentIngredient.percentage}%`);
        return;
      }
      updatedSubIngredients[currentIngredient.id].push({ ...values, id: Date.now() });
      setSubIngredients(updatedSubIngredients);
      setIngredients(ingredients.map(ingredient =>
        ingredient.id === currentIngredient.id
          ? { ...ingredient, subIngredients: updatedSubIngredients[currentIngredient.id] }
          : ingredient
      ));
      subForm.resetFields();
      setIsModalVisible(false);
    });
  };

  const showSubIngredientModal = (ingredient) => {
    setCurrentIngredient(ingredient);
    setIsModalVisible(true);
    subForm.resetFields();
  };

  const showEditModal = (ingredient) => {
    setCurrentIngredient(ingredient);
    editForm.setFieldsValue({ name: ingredient.name, percentage: ingredient.percentage });
    setIsEditModalVisible(true);
  };

  const editIngredient = () => {
    editForm.validateFields().then(values => {
      setIngredients(ingredients.map(ingredient =>
        ingredient.id === currentIngredient.id ? { ...ingredient, ...values } : ingredient
      ));
      setIsEditModalVisible(false);
    });
  };

  const deleteIngredient = (ingredientId) => {
    const deletedIngredient = ingredients.find(ingredient => ingredient.id === ingredientId);
    setIngredients(ingredients.filter(ingredient => ingredient.id !== ingredientId));
    setCascaderOptions(transformDataToCascaderOptions(availableIngredientsData, ingredients.filter(ingredient => ingredient.id !== ingredientId).map(ing => ing.name)));
  };

  const menuItems = (ingredient) => [
    {
      key: 'edit',
      icon: <EditOutlined />,
      label: 'Редагувати',
      onClick: () => showEditModal(ingredient),
    },
    {
      key: 'delete',
      icon: <DeleteOutlined />,
      label: 'Видалити',
      onClick: () => deleteIngredient(ingredient.id),
    },
  ];

  const validateSubIngredientPercentage = (rule, value) => {
    if (value && currentIngredient && value > currentIngredient.percentage) {
      return Promise.reject(new Error(`The percentage cannot exceed the main ingredient's percentage (${currentIngredient.percentage}%)`));
    }
    return Promise.resolve();
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const renderContent = () => {
    if (!role) {
      console.log("Role is undefined, returning null");
      return null;
    }

    console.log("Rendering content for role:", role);

    return (
      <>
        {selectedKey === '1' && (
          <Card title="Назва складу" bordered={false} style={{ backgroundColor: 'white', marginBottom: '16px' }}>
            <Form form={form} layout="vertical" onValuesChange={(_, allValues) => updateGeneratedName(allValues)}>
              <CompositionNameAndIcon normFile={normFile} disabled={viewOnly} />
              <Form.Item style={{ marginBottom: 0 }}>
                <Row align="middle">
                  <Col flex="none" style={{ marginRight: 16 }}>
                    ID
                  </Col>
                  <Col flex="auto">
                    <Form.Item name="articleNumber" noStyle>
                      <Input disabled style={{ width: '150px' }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
        )}
        {selectedKey === '2' && (
          <Card title="Інгредієнти складу" bordered={false} style={{ marginBottom: '16px' }}>
            <IngredientForm onAdd={addIngredient} cascaderOptions={cascaderOptions} ingredientForm={ingredientForm} ingredients={ingredients} viewOnly={viewOnly} displayRender={displayRender} />
            <List
              dataSource={ingredients}
              renderItem={ingredient => (
                <div key={ingredient.id} style={{ marginBottom: '10px' }}>
                  <List.Item
                    style={{
                      backgroundColor: '#f0f2f5',
                      borderRadius: '4px',
                      padding: '10px'
                    }}
                  >
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>{`${ingredient.name} ${ingredient.percentage ? `${ingredient.percentage}%` : ''}`}</span>
                      {!viewOnly && (
                        <Dropdown menu={{ items: [{ key: 'edit', icon: <EditOutlined />, label: 'Редагувати' }, { key: 'delete', icon: <DeleteOutlined />, label: 'Видалити' }] }} trigger={['click']}>
                          <Button type="link" icon={<MoreOutlined />} />
                        </Dropdown>
                      )}
                    </div>
                  </List.Item>
                  {ingredient.subIngredients && ingredient.subIngredients.length > 0 && (
                    <div style={{ paddingLeft: '20px', borderLeft: '2px solid #d9d9d9', marginLeft: '20px' }}>
                      <List
                        dataSource={ingredient.subIngredients}
                        renderItem={subItem => (
                          <List.Item style={{ backgroundColor: '#fafafa', borderRadius: '4px', marginBottom: '5px' }}>
                            <Space>
                              <span style={{ paddingLeft: '20px' }}>{`${subItem.name} ${subItem.percentage ? `${subItem.percentage}%` : ''}`}</span>
                              {!viewOnly && (
                                <Button type="link" icon={<DeleteOutlined />} onClick={() => {
                                  const updatedSubIngredients = ingredient.subIngredients.filter(sub => sub.id !== subItem.id);
                                  setIngredients(ingredients.map(ing =>
                                    ing.id === ingredient.id ? { ...ing, subIngredients: updatedSubIngredients } : ing
                                  ));
                                }} />
                              )}
                            </Space>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            />
          </Card>
        )}
        {selectedKey === '3' && (
          <Card title="Харчові добавки" bordered={false} style={{ marginBottom: '16px' }}>
            <NutritionalSupplements supplementValues={supplementValues} setSupplementValues={setSupplementValues} disabled={viewOnly} />
          </Card>
        )}
      </>
    );
  };

  return (
    <Spin spinning={loading} size="large" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: '#001529' }}>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} onClick={handleMenuClick} />
        </Sider>
        <Layout>
          <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0, backgroundColor: 'white' }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{ fontSize: '16px', width: 64, height: 64 }}
            />
            <div style={{ flex: 1, marginLeft: '16px', marginRight: '16px', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', lineHeight: 1.25 }}>
              <span>Назва складу: {generatedName}</span>
            </div>
            {selectedKey === '1' && (
              <div>
                <Button type="primary" onClick={handleSave} style={{ marginRight: '8px' }} disabled={viewOnly}>
                  Зберегти
                </Button>
                <Button onClick={handleExit}>
                  Вийти
                </Button>
              </div>
            )}
          </Header>
          <Content style={{ margin: '16px 16px 24px', padding: 15, minHeight: 280 }}>
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
      <SubIngredientModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={addSubIngredient}
        form={subForm}
        validateSubIngredientPercentage={validateSubIngredientPercentage}
        currentIngredient={currentIngredient}
        cascaderOptions={cascaderOptions}
        subIngredients={subIngredients[currentIngredient?.id] || []}
        disabled={viewOnly}
      />
      <EditIngredientModal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={editIngredient}
        form={editForm}
        disabled={viewOnly}
      />
    </Spin>
  );
};

const IngredientForm = ({ onAdd, cascaderOptions, ingredientForm, ingredients, viewOnly, displayRender }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleValuesChange = (_, allValues) => {
    setIsButtonDisabled(!allValues.name);
  };

  useEffect(() => {
    ingredientForm.setFieldsValue({ name: undefined, percentage: undefined });
    setIsButtonDisabled(true);
  }, [ingredientForm]);

  const handleAdd = () => {
    ingredientForm.validateFields().then(values => {
      onAdd(values);
      setIsButtonDisabled(true);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const addedIngredientNames = ingredients.map(ingredient => ingredient.name);

  return (
    <Form form={ingredientForm} layout="inline" style={{ marginBottom: '20px' }} onValuesChange={handleValuesChange}>
      <Form.Item name="name" rules={[{ required: true, message: 'Please select an ingredient' }]}>
        <Cascader
          options={cascaderOptions}
          showSearch={{ filter: (inputValue, path) => path.some(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) }}
          placeholder="Select ingredient"
          style={{ width: 400 }}
          displayRender={displayRender}
          disabled={viewOnly}
        />
      </Form.Item>
      <Form.Item name="percentage">
        <InputNumber placeholder="%" min={0} max={100} disabled={viewOnly} />
      </Form.Item>
      {!viewOnly && (
        <Button type="primary" onClick={handleAdd} disabled={isButtonDisabled}>
          <PlusOutlined /> Додати інгредієнт
        </Button>
      )}
    </Form>
  );
};

const SubIngredientModal = ({ visible, onCancel, onOk, form, validateSubIngredientPercentage, currentIngredient, cascaderOptions, subIngredients, disabled, displayRender }) => {
  const addedSubIngredientNames = subIngredients.map(subIngredient => subIngredient.name);

  return (
    <Modal title={`Add Sub-Ingredient to ${currentIngredient ? currentIngredient.name : ''}`} open={visible} onCancel={onCancel} onOk={onOk} okButtonProps={{ disabled }}>
      <Form form={form} layout="inline">
        <Form.Item name="name" rules={[{ required: true, message: 'Please select sub-ingredient' }]}>
          <Cascader
            options={cascaderOptions}
            showSearch={{ filter: (inputValue, path) => path.some(option => option.label.toLowerCase().includes(inputValue.toLowerCase())) }}
            placeholder="Select sub-ingredient"
            style={{ width: 400 }}
            displayRender={displayRender}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item name="percentage" rules={[{ validator: validateSubIngredientPercentage }]}>
          <InputNumber placeholder="%" min={0} max={100} disabled={disabled} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditIngredientModal = ({ visible, onCancel, onOk, form, disabled }) => (
  <Modal title="Edit Ingredient" open={visible} onCancel={onCancel} onOk={onOk} okButtonProps={{ disabled }}>
    <Form form={form} layout="inline">
      <Form.Item name="name">
        <Input placeholder="Ingredient name" disabled />
      </Form.Item>
      <Form.Item name="percentage" rules={[{ required: true, message: 'Please enter percentage' }]}>
        <InputNumber placeholder="%" min={0} max={100} disabled={disabled} />
      </Form.Item>
    </Form>
  </Modal>
);

export default AddCompositionPage;
