// home/servers/feedcraft/client/src/components/ProductCard.js

import React from 'react';
import { Card, Tag, Divider, Dropdown, Space } from 'antd';
import { SettingOutlined, EditOutlined, EllipsisOutlined, DownOutlined } from '@ant-design/icons';

const { Meta } = Card;

const ProductCard = ({ product, availableWeights }) => {
  const { name, price, price_old, images, slug, characteristics } = product;

  // Отримуємо всі значення призначень товару
  const assignmentList = characteristics?.priznachennja?.map((item) => item.value.ua) || ['Не вказано'];

  // Обробка кліку по картці
  const handleCardClick = () => {
    const url = `https://homefood.ua/${slug}`;
    window.open(url, '_blank'); // Відкриває посилання в новій вкладці
  };

  // Перетворюємо assignmentList у пункти випадаючого меню без посилань
  const items = assignmentList.map((assignment, index) => ({
    key: index.toString(),
    label: (
      <span key={index} style={{ fontSize: '12px', marginBottom: '5px' }}>
        {assignment}
      </span>
    ),
  }));

  return (
    <Card
      hoverable
      style={{
        width: 300, // Ширина картки
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        margin: '10px',
        textAlign: 'left', // Вирівнюємо текст по лівому краю
        height: '100%',
      }}
      cover={
        <img
          alt={name}
          src={images[0]}
          style={{ height: '200px', objectFit: 'contain', padding: '10px' }}
        />
      }
      onClick={handleCardClick}
      actions={
        availableWeights.length > 0
          ? availableWeights.map((weight, index) => (
              <span key={index} style={{ fontSize: '14px' }}>
                {weight.value}
              </span>
            ))
          : [<SettingOutlined key="setting" />, <EditOutlined key="edit" />, <EllipsisOutlined key="ellipsis" />]
      }
      className="css-1pg9a38" // Додаємо клас для стилю
    >
      <Meta
        title={
          <>
            {/* Відображаємо ціну товару перед title */}
            <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0', color: '#1890ff' }}>
              {price && (
                <>
                  <span>{price} грн</span>
                  {price_old && price_old !== price ? (
                    <>
                      &nbsp;
                      <span style={{ textDecoration: 'line-through', color: '#999', fontSize: '12px' }}>
                        {price_old} грн
                      </span>
                    </>
                  ) : null}
                </>
              )}
            </p>
            <span
              style={{
                fontSize: '12px', // Назва тепер має 12px
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3, // Обмеження title до 3 рядків
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                lineHeight: '1.4em',
                height: '4.5em',
              }}
            >
              {name}
            </span>
          </>
        }
        description={
          <div style={{ marginTop: '10px' }}>
            {/* Додаємо випадаючий список для призначень товару */}
            <Dropdown
              menu={{
                items,
              }}
              trigger={['hover']} // Тригером є наведення
            >
              <div
                style={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.40)', // Застосування кольору для тексту "Призначення"
                }}
              >
                <Divider
                  orientation="left"
                  style={{ fontSize: '12px', marginTop: '10px', marginLeft: '0px', color: 'rgba(0, 0, 0, 0.2)' }} // Прибрано відступ зліва
                >
                  <Space
                    style={{ transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1890ff')} // Зміна кольору при наведенні
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'rgba(0, 0, 0, 0.40)')} // Повернення кольору
                  >
                    Призначення <DownOutlined />
                  </Space>
                </Divider>
              </div>
            </Dropdown>
          </div>
        }
      />
    </Card>
  );
};

export default ProductCard;
