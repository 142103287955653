import React from 'react';
import { Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { DatabaseOutlined, LineChartOutlined, RocketOutlined, BarChartOutlined, FormOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade';
import './HomePage.css';

const HomePage = () => (
  <div className="home-page">
    <div className="banner">
      <Row className="banner-content" align="middle" justify="center">
        <Col xs={24} md={8} className="banner-col">
          <Fade top delay={300}>
            <img src="https://homefood.ua/content/images/30/0dlia-kartynok_2-37366940052132_+901a4871ae.png" alt="Icon" className="icon-large" />
          </Fade>
        </Col>
        <Col xs={24} md={16} className="banner-col banner-col-right">
          <Fade top delay={300}>
            <img src="https://homefood.ua/content/images/30/0dlia-kartynok_2-31608988389359_+8879b3bfd0.png" alt="Logo" className="logo-large" />
          </Fade>
          <Fade top delay={600}>
            <p className="banner-text">Створіть раціон вашого улюбленця за допомогою простих у використанні інструментів</p>
          </Fade>
          <Fade top delay={900}>
            <Button type="primary" size="large">
              <Link to="/ingredient-generator">Почати</Link>
            </Button>
          </Fade>
        </Col>
      </Row>
    </div>
    <div className="features">
      <h2>Можливості сервісу</h2>
      <div className="cards-container">
        <div className="card">
          <DatabaseOutlined className="card-icon" />
          <h3>Генератор складів</h3>
          <p>Створюйте власні рецепти для раціону улюбленця</p>
          <Button type="primary"><Link to="/ingredient-generator">Створити рецепт</Link></Button>
        </div>
        <div className="card">
          <LineChartOutlined className="card-icon" />
          <h3>Трекер прогресу</h3>
          <p>Відстежуйте кількість проданих товарів</p>
          <Button type="primary"><Link to="/progress">Відстежити</Link></Button>
        </div>
        <div className="card">
          <FormOutlined className="card-icon" />
          <h3>Опитувальник</h3>
          <p>Заповніть опитування для отримання персоналізованих рекомендацій.</p>
          <Button type="primary"><Link to="/survey">Пройти опитування</Link></Button>
        </div>
        <div className="card">
          <BarChartOutlined className="card-icon" />
          <h3>Статистика</h3>
          <p>Перегляньте результати опитування та аналітику.</p>
          <Button type="primary"><Link to="/stats">Переглянути статистику</Link></Button>
        </div>
      </div>
    </div>
    <footer className="footer">
      <p>©2024 FeedCraft. All rights reserved.</p>
    </footer>
  </div>
);

export default HomePage;
