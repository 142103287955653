import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Dropdown, Menu, message, Image, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

const IngredientGeneratorPage = () => {
  const [compositions, setCompositions] = useState([]);
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCompositions();
  }, []);

  const fetchCompositions = async () => {
    try {
      const response = await axios.get('/api/compositions');
      setCompositions(response.data);
    } catch (error) {
      console.error('Error fetching compositions:', error);
      message.error('Failed to fetch compositions');
    }
  };

  const handleDeleteComposition = async (articleNumber) => {
    try {
      await axios.delete(`/api/compositions/${articleNumber}`);
      message.success('Composition deleted successfully');
      fetchCompositions();
    } catch (error) {
      console.error('Error deleting composition:', error);
      message.error('Failed to delete composition');
    }
  };

  const items = (composition) => [
    {
      key: 'view',
      label: 'Перегляд',
      onClick: () => navigate(`/add-composition/${composition.articleNumber}?view=true`),
    },
    {
      key: 'edit',
      label: 'Редагувати',
      onClick: () => navigate(`/add-composition/${composition.articleNumber}`),
      disabled: role === 'viewer',
    },
    {
      key: 'delete',
      label: 'Видалити',
      onClick: () => handleDeleteComposition(composition.articleNumber),
      disabled: role !== 'admin' && role !== 'editor',
    },
  ];
  

  const columns = [
    {
      title: 'Іконка',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => <Image width={50} src={icon} alt="Composition Icon" />
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
      render: (name) => name
    },
    {
      title: 'Дії',
      key: 'actions',
      render: (_, composition) => (
        <Dropdown overlay={<Menu items={items(composition)} />} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Дії
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
        <h2>Склади</h2>
        {(role === 'admin' || role === 'editor') && (
          <Button type="primary" onClick={() => navigate('/add-composition')}>Додати новий склад</Button>
        )}
      </div>
      <Table columns={columns} dataSource={compositions} rowKey="_id" />
    </div>
  );
};

export default IngredientGeneratorPage;
