import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import axios from 'axios';

const StatsPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchStats = async () => {
      const response = await axios.get('/api/survey/stats');
      setData(response.data);
    };

    fetchStats();
  }, []);

  const columns = [
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Question', dataIndex: ['answers', 'questionText'], key: 'question' },
    { title: 'Answer', dataIndex: ['answers', 'selectedOption'], key: 'answer' },
  ];

  return (
    <div>
      <h2>Статистика опитувань</h2>
      <Table columns={columns} dataSource={data} rowKey="_id" />
    </div>
  );
};

export default StatsPage;
