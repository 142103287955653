import React from 'react';

export const catQuestions = [
    { id: 1, question: "Кличка", type: "text", value: "name" },
    { id: 2, question: "Вік", type: "radio", options: [
      { label: "0-12 місяців", value: "0-12" },
      { label: "1-7 років", value: "1-7" },
      { label: "7+ років", value: "7+" }
    ], value: "age" 
    },
    { id: 3, question: "Порода", type: "select", options: [
      { label: "Короткошерсті породи: британська короткошерста, американська короткошерста, скоттіш-фолд, сіамська, абіссинська, бенгальська, єгипетська мау", value: "shorthair" },
      { label: "Довгошерсті породи: мейн-кун, персидська, сибірська, норвезька лісова, гімалайська, рагдолл, турецька ангора, невська маскарадна", value: "longhair" },
      { label: "Безшерсті породи: сфінкс, пітерболд, український левкой", value: "hairless" },
      { label: "Екзотичні породи: орієнтальна короткошерста, саванна, тойгер, чаузі, сінгапурська, корат, манчкін", value: "exotic" },
      { label: "Домашній кіт", value: "outbred" },
    ], value: "breed"
    },
    { id: 4, question: "Довжина шерсті", type: "radio", options: [
      { label: "Довга", value: "longHaired" },
      { label: "Коротка", value: "shortHaired" },
      { label: "Відсутня", value: "hairless" }
    ], value: "furType", 
    condition: (answers) => answers.breed?.value === 'outbred' 
    },
    { id: 5, question: "Колір шерсті", type: "radio", options: [
      { label: "білий", value: "white" },
      { label: "чорний", value: "black" },
      { label: "сірий", value: "gray" },
      { label: "кольоровий", value: "colorful" },
      { label: "рудий", value: "ginger" }
    ], value: "furColor", 
    condition: (answers) => answers.breed?.value !== 'hairless' && answers.furType?.value !== 'hairless' 
    },
    { id: 6, question: "Схильна до утворення ковтунів?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "matting",
    condition: (answers) => answers.breed?.value !== 'hairless' && answers.furType?.value !== 'hairless' 
    },
    { id: 7, question: "Тип статури за 5-бальною шкалою", type: "radio", options: [
        { 
          label: "виснажений", 
          value: "emaciated", 
          image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-56063772544341_+7c195c650f.png"
        },
        { 
          label: "худий", 
          value: "thin", 
          image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-96133786513687_+0ad86fa41f.png"
        },
        { 
          label: "нормальний", 
          value: "normal", 
          image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-92082788635014_+43245b34a9.png"
        },
        { 
          label: "вгодований", 
          value: "overweight", 
          image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-34118530949192_+327b5fb7ea.png"
        },
        { 
          label: "ожиріння", 
          value: "obesity", 
          image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-78243704257267_+e9dc2ce9e8.png"
        }
      ], value: "bodyType"
    },
    { id: 8, question: "Стать", type: "radio", options: [
        { label: "Самка", value: "female" },
        { label: "Самець", value: "male" }
      ], value: "gender"
    },
    { id: 9, question: "Тваринка стерилізована/кастрована?", type: "radio", options: [
      { label: "Так", value: "yes" },
      { label: "Ні", value: "no" }
    ], value: "neutered" 
    },
    { id: 10, question: "Чи народжує кішка?", type: "radio", options: [
        { label: "ні, ніколи", value: "never" },
        { label: "так, 3 рази на рік", value: "3_times" },
        { label: "так, 2 рази на рік", value: "2_times" },
        { label: "так, 1 раз на рік", value: "1_time" },
        { label: "так, рідше одного разу на рік", value: "rarely" },
        { label: "ні, але плануємо вагітність", value: "plan" }
      ], value: "birthFrequency", 
      condition: (answers) => answers.gender?.value === 'female' && answers.neutered?.value === 'no' 
    },
    { id: 11, question: "Чи є хронічні захворювання?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "chronicIllness"
    },
    { id: 12, question: "Чи приймає ліки для підтримки стану?", type: "checkbox", options: [
        { label: "Інсулін", value: "insulin" },
        { label: "Нестероїдні протизапальні препарати", value: "nsaids" },
        { label: "Тироксин", value: "thyroxin" },
        { label: "Стероїди", value: "steroids" },
        { label: "Протизаплідні", value: "contraceptives" },
        { label: "Ні", value: "none" }
      ], value: "medications", 
      condition: (answers) => answers.chronicIllness?.value === 'yes' 
    },
    { id: 13, question: "Чи є проблеми із зубами або яснами?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "dentalIssues"
    },
    { id: 14, question: "Чи бувають проблеми з травленням?", type: "checkbox", options: [
        { label: "Запори", value: "constipation" },
        { label: "Проноси", value: "diarrhea" },
        { label: "Блювота", value: "vomiting" },
        { label: "Ні", value: "none" }
      ], value: "digestiveIssues"
    },
    { id: 15, question: "Чи є алергії або непереносимість інгредієнтів?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "allergies"
    },
    { id: 16, question: "Симптоми алергії", type: "checkbox", options: [
        { label: "Свербіж", value: "itching" },
        { label: "Облисіння", value: "hairLoss" },
        { label: "Червоні плями", value: "redSpots" },
        { label: "Розчухування", value: "scratching" },
        { label: "Запалення вух", value: "earInflammation" },
        { label: "Течуть очі", value: "wateryEyes" }
      ], value: "allergySymptoms", 
      condition: (answers) => answers.allergies?.value === 'yes' 
    },
    { id: 17, question: "Тип харчування", type: "checkbox", options: [
        { label: "Сухий корм", value: "dryFood" },
        { label: "Вологий корм", value: "wetFood" },
        { label: "Домашня їжа", value: "homemade" },
        { label: "Залишки зі столу", value: "tableScraps" }
      ], value: "dietType"
    },
    { id: 18, question: "Частота годування", type: "radio", options: [
        { label: "Раз на день", value: "onceADay" },
        { label: "Двічі на день", value: "twiceADay" },
        { label: "Більше", value: "moreThanTwice" },
        { label: "Їжа у постійному доступі", value: "freeAccess" }
      ], value: "feedingFrequency"
    },
    { id: 19, question: "Кількість води, що тваринка випиває на день", type: "radio", options: [
        { label: "20-40 мл", value: "20-40" },
        { label: "40-60 мл", value: "40-60" },
        { label: "60-100 мл", value: "60-100" },
        { label: "100-300 мл", value: "100-300" },
        { label: "Не відслідковую", value: "notTracking" }
      ], value: "waterIntake"
    },
    { id: 20, question: "Тип води", type: "radio", options: [
        { label: "З-під крана", value: "tapWater" },
        { label: "Фільтрована", value: "filteredWater" },
        { label: "Покупна", value: "bottledWater" },
        { label: "Спеціальна вода для котів", value: "specialCatWater" },
        { label: "Зі свердловини", value: "wellWater" }
      ], value: "waterType"
    },
    { id: 21, question: "Чи має (name) доступ до вулиці?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "outdoorAccess"
    },
    { id: 22, question: "Чи має (name) доступ до іграшок та/або кігтеточок?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "playComplexAccess"
    },
    { id: 23, question: "Скільки у вас вдома котів?", type: "radio", options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4 і більше", value: "4+" }
      ], value: "numberOfCats"
    },
    { id: 24, question: "Введіть ваш email для отримання рекомендацій з харчування", type: "text", value: "email", rules: [
        {
          required: true,
          message: 'Будь ласка, введіть ваш email!',
        },
        {
          type: 'email',
          message: 'Введіть правильний email!',
        },
      ]
    }
  ];

export default catQuestions;