import React, { useEffect, useState } from 'react';
import { Form, InputNumber, message, Space, Spin } from 'antd';
import axios from 'axios';

const translations = {
  "Vitamin A": "Вітамін А",
  "Vitamin D3": "Вітамін D3",
  "Vitamin C": "Вітамін С"
};

const unitsTranslation = {
  "IU": "МО",
  "mg": "мг"
};

const NutritionalSupplements = ({ supplementValues, setSupplementValues, disabled }) => {
  const [supplements, setSupplements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        console.log('Fetching nutritional supplements...');
        const response = await axios.get('/api/nutritional-supplements');
        console.log('Fetched nutritional supplements:', response.data);
        setSupplements(response.data);

        const initialValues = {};
        response.data.forEach(supplement => {
          initialValues[supplement.name] = supplementValues[supplement.name] || '';
        });
        setSupplementValues(initialValues);
        console.log('Initial supplement values:', initialValues);
      } catch (error) {
        message.error('Failed to fetch nutritional supplements');
        console.error('Error fetching nutritional supplements:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSupplements();
  }, []);

  const handleSupplementChange = (name, value) => {
    console.log(`Changing supplement value: ${name} = ${value}`);
    setSupplementValues(prev => ({ ...prev, [name]: value }));
  };

  return (
    <Spin spinning={loading}>
      <Form layout="inline">
        <Space wrap>
          {supplements.map((supplement, index) => (
            <Form.Item key={index} style={{ marginBottom: 0 }}>
              <InputNumber
                min={0}
                step={50}
                style={{ width: 205 }}
                value={supplementValues[supplement.name] || ''}
                onChange={(value) => handleSupplementChange(supplement.name, value)}
                addonBefore={translations[supplement.name]}
                addonAfter={unitsTranslation[supplement.unit]}
                disabled={disabled}
              />
            </Form.Item>
          ))}
        </Space>
      </Form>
    </Spin>
  );
};

export default NutritionalSupplements;
