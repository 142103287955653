// home/servers/feedcraft/client/src/pages/SurveyResults.js

import React from 'react';
import { Card } from 'antd';
import GroupedProducts from '../components/GroupedProducts'; // Import the GroupedProducts component

const SurveyResults = ({ products }) => {
  return (
    <div style={{ maxWidth: '1300px', margin: '0 auto', padding: '20px' }}>
      <Card title="Рекомендовані товари" bordered={false} style={{ margin: '20px 0' }}>
        <p>На основі ваших відповідей, ми рекомендуємо такі товари:</p>
        {products.length > 0 ? (
          <GroupedProducts products={products} /> // Display the grouped products
        ) : (
          <p>Немає доступних товарів за вашими запитами.</p>
        )}
      </Card>
    </div>
  );
};

export default SurveyResults;
