import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthPage } from './pages/AuthPage';
import IngredientGeneratorPage from './pages/IngredientGeneratorPage';
import AddCompositionPage from './pages/AddCompositionPage';
import ProgressPage from './pages/ProgressPage';
import HomePage from './pages/HomePage';
import SurveyFlow from './pages/SurveyFlow'; // Імпорт сторінки SurveyFlow
import StatsPage from './pages/StatsPage';   // Імпорт сторінки StatsPage

export const useRoutes = (isAuthenticated) => {
    if (isAuthenticated) {
        return (
            <Routes>
                <Route path="/home" element={<HomePage />} /> {/* Додано новий маршрут */}
                <Route path="/ingredient-generator" element={<IngredientGeneratorPage />} />
                <Route path="/add-composition/:articleNumber?" element={<AddCompositionPage />} />
                <Route path="/progress" element={<ProgressPage />} />
                <Route path="/survey" element={<SurveyFlow />} /> {/* Додано маршрут для опитувальника */}
                <Route path="/stats" element={<StatsPage />} />   {/* Додано маршрут для статистики */}
                <Route path="*" element={<Navigate to="/home" />} /> {/* Змінено перенаправлення за замовчуванням */}
            </Routes>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
