import React from 'react';

export const dogQuestions = [
    { id: 1, question: "Кличка", type: "text", value: "name" },
    { id: 2, question: "Порода", type: "select", options: [
      { label: "Маленькі породи (до 10 кг): чихуахуа, йоркширський тер'єр, померанський шпіц, джек-рассел-тер'єр, мопс, французький бульдог, ши-тцу, пекінес", value: "MINI" },
      { label: "Середні породи (10-25 кг): бігль, коргі, шелті, кокер-спанієль, басенджі, бордоський дог, шарпей, шотландський тер'єр", value: "MEDIUM" },
      { label: "Великі породи (25-45 кг): лабрадор-ретрівер, німецька вівчарка, золотистий ретрівер, акіта-іну, доберман, далматинець, ротвейлер, боксер", value: "MAXI" },
      { label: "Гігантські породи (понад 45 кг): сенбернар, мастиф, ньюфаундленд, ірландський вовкодав, дог, леонбергер, кавказька вівчарка, алабай", value: "GIANT" },
      ], value: "breed"
    },
    { id: 3, question: "Вік", type: "radio", options: [
        { label: "0-12 міс", value: "0-12" },
        { label: "1-7 років", value: "1-7" },
        { label: "7+ років", value: "7+" }
      ], value: "age"
    },
    { id: 4, question: "Стать", type: "radio", options: [
      { label: "Самець", value: "male" },
      { label: "Самка", value: "female" }
    ], value: "gender"
    },
    { id: 5, question: "Тваринка стерилізована/кастрована?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "neutered"
    },
    { id: 6, question: "Чи народжує собачка?", type: "radio", options: [
      { label: "ні, ніколи", value: "never" },
      { label: "так, 3 рази на рік", value: "3_times" },
      { label: "так, 2 рази на рік", value: "2_times" },
      { label: "так, 1 раз на рік", value: "1_time" },
      { label: "так, рідше одного разу на рік", value: "rarely" },
      { label: "ні, але плануємо вагітність", value: "plan" }
    ], value: "birthFrequency", condition: (answers) => answers.gender?.value === 'female' && answers.neutered?.value === 'no' },
    { id: 7, question: "Тип статури за 5-бальною шкалою", type: "radio", options: [
      { 
        label: "виснажений", 
        value: "emaciated", 
        image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-73601875842735_+2fba1ec903.png"
      },
      { 
        label: "худий", 
        value: "thin", 
        image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-20120615919959_+b51813a55c.png"
      },
      { 
        label: "нормальний", 
        value: "normal", 
        image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-46370528567062_+9aaf90fb3f.png"
      },
      { 
        label: "вгодований", 
        value: "overweight", 
        image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-39276982324771_+11e47c742a.png"
      },
      { 
        label: "ожиріння", 
        value: "obesity", 
        image: "https://homefood.ua/content/images/30/0dlia-kartynok_2-41137770834097_+81cccb5e8b.png"
      }
      ], value: "bodyType"
    },
    { id: 8, question: "Тип активності за 3-бальною шкалою", type: "radio", options: [
        { label: "пасивний", value: "passive" },
        { label: "середня активність", value: "moderate" },
        { label: "гіперактивний", value: "hyperactive" }
      ], value: "activityLevel"
    },
    { id: 9, question: "Тип харчування", type: "checkbox", options: [
        { label: "Сухий корм", value: "dryFood" },
        { label: "Вологий корм", value: "wetFood" },
        { label: "Домашня їжа", value: "homemade" },
        { label: "Залишки зі столу", value: "tableScraps" }
      ], value: "dietType"
    },
    { id: 10, question: "Як би ви оцінили апетит?", type: "radio", options: [
        { label: "Хороший їдець", value: "goodEater" },
        { label: "Іноді вередує", value: "pickySometimes" },
        { label: "Сильно вибагливий у їжі", value: "veryPicky" }
      ], value: "appetite"
    },
    { id: 11, question: "Колір шерсті", type: "radio", options: [
        { label: "білий", value: "white" },
        { label: "чорний", value: "black" },
        { label: "кольоровий", value: "colorful" },
        { label: "рудий", value: "ginger" }
      ], value: "furColor"
    },
    { id: 12, question: "Тип шерсті", type: "radio", options: [
        { label: "Пряма", value: "straight" },
        { label: "Кучерява", value: "curly" },
        { label: "Треба стригти", value: "needsClipping" },
        { label: "Жорсткошерста", value: "wireHaired" }
      ], value: "furTexture"
    },
    { id: 13, question: "Чи є хронічні захворювання?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "chronicIllness"
    },
    { id: 14, question: "Чи приймає ліки для підтримки стану?", type: "checkbox", options: [
        { label: "Інсулін", value: "insulin" },
        { label: "Нестероїдні протизапальні препарати", value: "nsaids" },
        { label: "Тироксин", value: "thyroxin" },
        { label: "Стероїди", value: "steroids" },
        { label: "Протизаплідні", value: "contraceptives" },
        { label: "Ні", value: "none" }
      ], value: "medications", condition: (answers) => answers.chronicIllness?.value === 'yes' },
    { id: 15, question: "Чи є проблеми із зубами або яснами?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "dentalIssues"
    },
    { id: 16, question: "Чи бувають проблеми з травленням?", type: "checkbox", options: [
        { label: "Запори", value: "constipation" },
        { label: "Проноси", value: "diarrhea" },
        { label: "Блювота", value: "vomiting" },
        { label: "Ні", value: "none" }
      ], value: "digestiveIssues"
    },
    { id: 17, question: "Чи є алергії або непереносимість інгредієнтів?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "allergies"
    },
    { id: 18, question: "Симптоми алергії", type: "checkbox", options: [
        { label: "Свербіж", value: "itching" },
        { label: "Облисіння", value: "hairLoss" },
        { label: "Червоні плями", value: "redSpots" },
        { label: "Розчухування", value: "scratching" },
        { label: "Запалення вух", value: "earInflammation" },
        { label: "Течуть очі", value: "wateryEyes" }
      ], value: "allergySymptoms", condition: (answers) => answers.allergies?.value === 'yes' },
    { id: 19, question: "Кількість води, що випивається на день", type: "radio", options: [
        { label: "50-100 мл", value: "50-100" },
        { label: "100-300 мл", value: "100-300" },
        { label: "300-1000 мл", value: "300-1000" },
        { label: "Більше 1 л", value: "1L+" },
        { label: "Не відслідковую", value: "notTracking" }
      ], value: "waterIntake"
    },
    { id: 20, question: "Чи є проблеми з поведінкою?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], value: "behaviorIssues"
    },
    { id: 21, question: "Чому хотіли б приділити особливу увагу у підборі харчування?", type: "checkbox", options: [
        { label: "Ріст", value: "growth" },
        { label: "Поліпшення шерсті", value: "furImprovement" },
        { label: "Контроль ваги", value: "weightControl" },
        { label: "Підтримка суглобів", value: "jointSupport" },
        { label: "Підтримка серця", value: "heartSupport" },
        { label: "Старіння", value: "aging" },
        { label: "Пологи, годування", value: "breeding" }
      ], value: "specialAttention"
    },
    { id: 22, question: "Смакові переваги собаки", type: "checkbox", options: [
        { label: "М'ясо", value: "meat" },
        { label: "Риба", value: "fish" },
        { label: "Птах", value: "poultry" },
        { label: "Яйце", value: "egg" },
        { label: "Субпродукти", value: "offal" },
        { label: "Овочі", value: "vegetables" },
        { label: "Каші", value: "grains" },
        { label: "Фрукти", value: "fruits" },
        { label: "Масло лосося", value: "salmonOil" },
        { label: "Кістки", value: "bones" },
        { label: "Інше", value: "other" }
      ], value: "tastePreferences"
    },
    { id: 23, question: "Який бренд (name) їсть зараз?", type: "text", value: "currentBrand" },
    { id: 24, question: "Введіть ваш email для отримання рекомендацій з харчування", type: "text", value: "email", rules: [
        {
          required: true,
          message: 'Будь ласка, введіть ваш email!',
        },
        {
          type: 'email',
          message: 'Введіть правильний email!',
        },
      ]
    }
  ];

export default dogQuestions;