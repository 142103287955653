//home/servers/feedcraft/client/src/pages/SurveyFlow.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Radio, Input, Card, Progress, Checkbox, Select, Spin } from 'antd';
import axios from 'axios';
import { catQuestions } from '../components/questions/CatQuestions';
import { dogQuestions } from '../components/questions/DogQuestions';
import { fetchProductsLocally, calculateProductRecommendations } from '../services/productService';
import SurveyResults from './SurveyResults'; // Імпорт нового компонента для результатів

// Функція для валідації email
const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const SurveyFlow = () => {
  const [petType, setPetType] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [completed, setCompleted] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // Лоадер
  const [error, setError] = useState(null);

  const getDynamicQuestionText = (question) => {
    if (question && question.question.includes('(name)') && answers.name) {
      return question.question.replace('(name)', answers.name.value);
    }
    return question ? question.question : '';
  };

  const questions = petType === 'cat' ? catQuestions : petType === 'dog' ? dogQuestions : [];

  useEffect(() => {
    const question = questions[currentQuestionIndex];
    if (question && question.condition && !question.condition(answers)) {
      handleNext();
    }
  }, [currentQuestionIndex, answers, questions]);

  const handleNext = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      console.log('Зібрані відповіді:', answers);

      // Формуємо характеристики для MongoDB запиту
      const characteristics = calculateProductRecommendations(petType, answers);

      if (Object.keys(characteristics).length > 0) {
        console.log('Рекомендовані характеристики для продуктів:', characteristics);
        
        // Показуємо лоадер
        setLoading(true);

        // Отримуємо товари з локальної бази даних на основі характеристик
        await fetchProductsLocally(characteristics, setProducts);

        // Сховати лоадер після отримання відповідей
        setLoading(false);
      } else {
        console.warn('Характеристики для MongoDB-запиту порожні, запит не виконано.');
      }

      axios.post('/api/survey/response', { petType, answers })
        .then(response => {
          console.log('Відповіді успішно збережені', response.data);
          setCompleted(true);
        })
        .catch(error => {
          console.error('Помилка при збереженні відповідей:', error);
          setError('Помилка при збереженні відповідей. Будь ласка, спробуйте ще раз.');
        });
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value || e.target.checked;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestionIndex]?.value]: {
        questionText: questions[currentQuestionIndex]?.question,
        selectedOption: questions[currentQuestionIndex]?.options ? value : null,
        value: value
      }
    }));
  };

  const renderFirstQuestion = () => (
    <Card title="Опитувальник" bordered={false}>
      <Form layout="vertical">
        <Form.Item label="Який у вас хвостик?">
          <Radio.Group onChange={(e) => setPetType(e.target.value)} style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Radio value="cat" style={{ marginRight: 16, marginBottom: 8, flex: '1 0 45%' }}>
              <div 
                style={{
                  textAlign: 'center',
                  border: '1px solid rgb(217, 217, 217)',
                  borderRadius: '8px',
                  padding: '8px'
                }}>
                <img
                  src="https://homefood.ua/content/images/30/0dlia-kartynok_2-78509377797457_+98c4c6c014.png"
                  alt="Котик"
                  style={{ width: '180px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div>Котик</div>
              </div>
            </Radio>
            <Radio value="dog" style={{ marginRight: 16, marginBottom: 8, flex: '1 0 45%' }}>
              <div 
                style={{
                  textAlign: 'center',
                  border: '1px solid rgb(217, 217, 217)',
                  borderRadius: '8px',
                  padding: '8px'
                }}>
                <img
                  src="https://homefood.ua/content/images/30/0dlia-kartynok_2-64573032813260_+9391af71c5.png"
                  alt="Песик"
                  style={{ width: '180px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div>Песик</div>
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Card>
  );

  const renderQuestion = (question) => {
    if (!question) return null;
    const shouldRender = question && (question.condition ? question.condition(answers) : true);

    if (!shouldRender) return null;

    const dynamicQuestionText = getDynamicQuestionText(question);

    return (
      <Card title="Опитувальник" bordered={false}>
        <Form layout="vertical">
          <Form.Item label={dynamicQuestionText}>
            {question && question.type === 'text' && (
              <Input
                placeholder="Ваша відповідь"
                onChange={handleChange}
                value={answers[question.value]?.value || ''}
              />
            )}
            {question && question.type === 'radio' && (
              <Radio.Group
                onChange={handleChange}
                value={answers[question.value]?.value || ''}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                {question.options && question.options.map((option, index) => (
                  <Radio key={index} value={option.value} style={{ marginRight: 16, marginBottom: 8, flex: '1 0 45%' }}>
                    <div
                      style={{
                        border: option.image ? '1px solid rgb(217, 217, 217)' : 'none',
                        borderRadius: option.image ? '8px' : '0',
                        padding: option.image ? '8px' : '0'
                      }}
                    >
                      {option.image && (
                        <img
                          src={option.image}
                          alt={option.label}
                          style={{
                            width: '150px',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                          }}
                        />
                      )}
                      <div style={{ textAlign: 'center' }}>{option.label}</div>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
            )}
            {question && question.type === 'checkbox' && (
              <Checkbox.Group
                onChange={(values) => handleChange({ target: { value: values } })}
                value={answers[question.value]?.value || []}
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                {question.options && question.options.map((option, index) => {
                  const isNoneSelected = answers[question.value]?.value?.includes('none');
                  const isOtherSelected = answers[question.value]?.value?.length > 0 && !isNoneSelected;

                  return (
                    <Checkbox
                      key={index}
                      value={option.value}
                      disabled={(option.value !== 'none' && isNoneSelected) || (option.value === 'none' && isOtherSelected)}
                      style={{ marginRight: 16, marginBottom: 8, flex: '1 0 45%' }}
                    >
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        {option.label}
                      </div>
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            )}
            {question && question.type === 'select' && (
              <Select
                showSearch
                placeholder="Оберіть варіант"
                optionFilterProp="children"
                onChange={(value) => handleChange({ target: { value } })}
                value={answers[question.value]?.value || ''}
                style={{ width: '100%' }}
              >
                {question.options && question.options.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              Попереднє питання
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const isEmailQuestion = questions[currentQuestionIndex]?.value === 'email';
                const emailValue = answers['email']?.value || '';

                if (isEmailQuestion && !validateEmail(emailValue)) {
                  setError('Будь ласка, введіть коректний email');
                } else {
                  handleNext();
                  setError(null);
                }
              }}
              disabled={
                !answers[questions[currentQuestionIndex]?.value]?.value ||
                (questions[currentQuestionIndex]?.value === 'email' && !validateEmail(answers['email']?.value))
              }
            >
              {currentQuestionIndex < questions.length - 1 ? 'Наступне питання' : 'Завершити опитування'}
            </Button>
          </div>
          <Progress
            percent={Math.round(((currentQuestionIndex + 1) / questions.length) * 100)}
            style={{ marginTop: '20px' }}
          />
        </Form>
      </Card>
    );
  };

  // У частині, де ви показуєте результати, зробимо картки більш широкими та додамо відступи
  const renderResults = () => (
    <SurveyResults products={products} /> // Використовуємо компонент для результатів
  );

  return (
    <div style={{ maxWidth: completed ? '1000px' : '600px', margin: '0 auto', padding: '20px' }}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        !petType ? renderFirstQuestion() : completed ? renderResults() : renderQuestion(questions[currentQuestionIndex])
      )}
    </div>
  );
};

export default SurveyFlow;
