import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useRoutes } from './routes';
import { AuthProvider, useAuth } from './context/AuthContext';
import { Navbar } from './components/Navbar';
import { Loader } from './components/Loader';
import 'antd/dist/reset.css';

const AppContent = () => {
    const { token, login, logout, userId, role, ready, isAuthenticated } = useAuth();
    const routes = useRoutes(isAuthenticated);

    console.log("AppContent rendered");
    console.log("Token:", token);
    console.log("UserId:", userId);
    console.log("Ready:", ready);
    console.log("IsAuthenticated:", isAuthenticated);
    console.log("Role:", role);

    if (!ready) {
        return <Loader />;
    }

    return (
        <>
            {isAuthenticated && <Navbar />}
            <div className="container">
                {routes}
            </div>
        </>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <AppContent />
            </Router>
        </AuthProvider>
    );
}

export default App;
