import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

function noop() {}

export const AuthContext = createContext({
    userId: null,
    token: null,
    role: null,
    login: noop,
    logout: noop,
    isAuthenticated: false,
    ready: false
});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null);
    const [role, setRole] = useState(null);
    const [ready, setReady] = useState(false);

    const login = useCallback((jwtToken, id, userRole) => {
        setToken(jwtToken);
        setUserId(id);
        setRole(userRole);
        localStorage.setItem('userData', JSON.stringify({
            userId: id, token: jwtToken, role: userRole
        }));
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setUserId(null);
        setRole(null);
        localStorage.removeItem('userData');
    }, []);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData && storedData.token) {
            login(storedData.token, storedData.userId, storedData.role);
        }
        setReady(true);
    }, [login]);

    const isAuthenticated = !!token;

    return (
        <AuthContext.Provider value={{ userId, token, role, login, logout, isAuthenticated, ready }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
