//home/servers/feedcraft/client/src/services/productService.js

import axios from 'axios';

// Функція для отримання продуктів з локальної бази даних
async function fetchProductsLocally(characteristics = {}, setProducts) {
  try {
    if (Object.keys(characteristics).length === 0) {
      console.warn('Характеристики для запиту порожні. Запит не буде надіслано.');
      return;
    }

    console.log('Формування запиту до бекенду з такими характеристиками:', JSON.stringify(characteristics));

    const response = await axios.post('http://localhost:5000/api/products/local', { characteristics });

    console.log('Отримана відповідь з бекенду:', response.data.products);

    setProducts(response.data.products);
  } catch (error) {
    console.error('Помилка при отриманні продуктів з бекенду:', error.message);
  }
}

// Функція для формування запиту на основі відповідей опитувальника
function calculateProductRecommendations(petType, answers) {
  const characteristics = {};

  console.log('Розрахунок характеристик на основі відповідей опитувальника:', JSON.stringify(answers, null, 2));

  if (answers.dietType?.value.includes('grainFree')) {
    characteristics['priznachennja'] = 12; // id для "Беззерновий"
  }

  if (answers.dietType?.value.includes('hypoallergenic')) {
    characteristics['priznachennja'] = 11; // id для "Гіпоалергенний"
  }

  if (answers.digestiveIssues?.value.length > 0) {
    characteristics['priznachennja'] = 11; // id для "При чутливому травленні"
  }

  if (answers.neutered?.value === 'yes') {
    characteristics['priznachennja'] = 8; // id для "Для кастрованих/стерилізованих"
  }

  console.log('Згенеровані характеристики для MongoDB-запиту:', JSON.stringify(characteristics, null, 2));

  return characteristics;
}

// Експортуємо всі необхідні функції
export { fetchProductsLocally, calculateProductRecommendations };
