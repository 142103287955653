import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/AuthContext';

const { Header } = Layout;

export const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useContext(AuthContext);
    const [menuVisible, setMenuVisible] = useState(false);

    const logoutHandler = () => {
        auth.logout();
        navigate('/');
    };

    const handleClick = e => {
        if (e.key === 'logout') {
            logoutHandler();
        }
        setMenuVisible(false);
    };

    const handleMenuClick = e => {
        e.preventDefault();
        setMenuVisible(!menuVisible);
    };

    const menu = (
        <Menu
            onClick={handleClick}
            items={[
                {
                    label: 'Вийти',
                    key: 'logout'
                }
            ]}
        />
    );

    // Hide the Navbar on the /progress route
    if (location.pathname === '/progress') {
        return null;
    }

    return (
        <Layout>
            <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 2rem' }}>
                <div className="logo" style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="https://homefood.ua/content/images/30/0dlia-kartynok_2-31608988389359_+8879b3bfd0.png" alt="Logo" style={{ height: '20px' }} />
                </div>
                <Dropdown overlay={menu} trigger={['click']} visible={menuVisible} onVisibleChange={setMenuVisible}>
                    <Button type="link" onClick={handleMenuClick} style={{ padding: 0 }}>
                        <UserOutlined style={{ fontSize: '1.5rem' }} />
                    </Button>
                </Dropdown>
            </Header>
        </Layout>
    );
};
