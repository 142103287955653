import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttp } from '../hooks/http.hook';
import { useMessage } from '../hooks/message.hook';
import { AuthContext } from '../context/AuthContext';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './AuthPage.css';

export const AuthPage = () => {
    const auth = useContext(AuthContext);
    const message = useMessage();
    const { loading, request, error, clearError } = useHttp();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    useEffect(() => {
        if (error) {
            message(error);
            clearError();
        }
    }, [error, message, clearError]);

    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData, form]);

    const changeHandler = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleLogin = async () => {
        try {
            const values = await form.validateFields();
            const { email, password } = values;
            const data = await request('/api/auth/login', 'POST', { email, password });
            auth.login(data.token, data.userId, data.role);
            navigate('/home'); // перенаправлення на HomePage
        } catch (e) {
            message(e.message);
        }
    };

    // const handleRegister = async () => {
    //     try {
    //         const values = await form.validateFields();
    //         const { email, password } = values;
    //         const data = await request('/api/auth/register', 'POST', { email, password });
    //         message(data.message);
    //         setFormData({ email: '', password: '' });
    //     } catch (e) {
    //         message(e.message);
    //     }
    // };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card style={{ width: 400, borderRadius: 15, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ textAlign: 'center' }}>
                    <img 
                        src="https://homefood.ua/content/images/30/0dlia-kartynok_2-18636063303819_+9f0d20f10b.png" 
                        alt="icon" 
                        style={{ marginBottom: 20, width: '20%', maxWidth: '50px', height: 'auto' }} 
                    />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <img 
                        src="https://homefood.ua/content/images/30/0dlia-kartynok_2-65207636396137_+44d87ff9db.png" 
                        alt="logo" 
                        style={{ marginBottom: 20, width: '80%', maxWidth: '200px', height: 'auto' }} 
                    />
                </div>
                <Form
                    form={form}
                    name="auth"
                    layout="vertical"
                    initialValues={formData}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'The input is not valid E-mail!' }
                        ]}
                    >
                        <Input 
                            prefix={<UserOutlined />} 
                            placeholder="Email" 
                            style={{ borderRadius: '5px' }}
                            name="email"
                            value={formData.email}
                            onChange={changeHandler}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { min: 8, message: 'Password must be at least 8 characters!' }
                        ]}
                    >
                        <Input.Password 
                            prefix={<LockOutlined />} 
                            placeholder="Password" 
                            style={{ borderRadius: '5px' }}
                            name="password"
                            value={formData.password}
                            onChange={changeHandler}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block loading={loading} onClick={handleLogin} style={{ marginBottom: 10 }}>
                            Log in
                        </Button>
                        {/* <Button type="default" block loading={loading} onClick={handleRegister} style={{ marginBottom: 10 }}>
                            Register
                        </Button> */}
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};
