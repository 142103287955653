import React, { useEffect, useState } from 'react';
import './ProgressPage.css';

const ProgressPage = () => {
    const [progressData, setProgressData] = useState({
        total: 0,
        article2027123: 0,
        article3027123: 0
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProgressData = async () => {
            try {
                const response = await fetch('/api/progress');

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const contentType = response.headers.get('content-type');

                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError("Received content is not JSON");
                }

                const data = await response.json();
                setProgressData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchProgressData();
        const intervalId = setInterval(fetchProgressData, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const getProgressPercentage = (articleCount, target = 250) => Math.floor((articleCount / target) * 100);
    const getStrokeDashoffset = (percentage, radius = 50) => {
        const circumference = 2 * Math.PI * radius;
        return circumference - (percentage / 100) * circumference;
    };

    return (
        <div id="progress-container">
            <div id="heading">На звіряче щастя</div>

            {loading && <div id="loading-gif">&nbsp;</div>}

            <div className="circular-progress-container">
                <div className="circular-progress" id="circular-progress-2027123">
                    <svg>
                        <defs>
                            <linearGradient id="gradient" x1="0%" x2="100%" y1="100%" y2="0%">
                                <stop offset="0%" style={{ stopColor: '#ff828d', stopOpacity: 1 }}></stop>
                                <stop offset="100%" style={{ stopColor: '#ff3737', stopOpacity: 1 }}></stop>
                            </linearGradient>
                        </defs>
                        <circle cx="60" cy="60" r="50"></circle>
                        <circle
                            className="progress"
                            cx="60"
                            cy="60"
                            r="50"
                            style={{
                                strokeDasharray: `${Math.PI * 2 * 50}`,
                                strokeDashoffset: getStrokeDashoffset(getProgressPercentage(progressData.article2027123))
                            }}
                        ></circle>
                    </svg>
                    <span id="progress-2027123">{getProgressPercentage(progressData.article2027123)}%</span>
                    <div className="article-text">Артикул 2027123</div>
                </div>

                <div className="circular-progress" id="circular-progress-3027123">
                    <svg>
                        <circle cx="60" cy="60" r="50"></circle>
                        <circle
                            className="progress"
                            cx="60"
                            cy="60"
                            r="50"
                            style={{
                                strokeDasharray: `${Math.PI * 2 * 50}`,
                                strokeDashoffset: getStrokeDashoffset(getProgressPercentage(progressData.article3027123))
                            }}
                        ></circle>
                    </svg>
                    <span id="progress-3027123">{getProgressPercentage(progressData.article3027123)}%</span>
                    <div className="article-text">Артикул 3027123</div>
                </div>
            </div>

            <div id="progress-bar-container" style={{ display: progressData.total ? 'flex' : 'none' }}>
                <div id="progress-bar" style={{ width: `${(progressData.total / 500) * 100}%` }}>
                    <span id="goal">{Math.floor((progressData.total / 500) * 100)}%</span>
                </div>
            </div>

            <div id="summary-container" style={{ display: progressData.total ? 'flex' : 'none' }}>
                <div>МЕТА</div>
                <div>ЗІБРАНО</div>
                <div>ЗАЛИШИЛОСЬ</div>
            </div>

            <div id="summary-values" style={{ display: progressData.total ? 'flex' : 'none' }}>
                <div id="goal-value">500</div>
                <div id="collected-value">{progressData.total}</div>
                <div id="remaining-value">{500 - progressData.total}</div>
            </div>
        </div>
    );
};

export default ProgressPage;
